<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="submitFormData">
      <validation-provider name="Price" v-slot="context" rules="required">
        <b-form-group label="Price (in NOK)">
          <b-form-input
            type="number"
            v-model="price"
            :state="getValidationState(context)"
            v-bind="context.ariaInput"
            placeholder="e.g 99.99"
          />

          <b-form-invalid-feedback v-bind="context.ariaMsg">
            {{ context.errors[0] }}
          </b-form-invalid-feedback>
        </b-form-group>
      </validation-provider>

      <b-row>
        <b-col>
          <validation-provider name="Start Date" v-slot="context" rules="required">
            <b-form-group label="Start Date">
              <b-form-datepicker
                locale="nb-NO"
                :start-weekday="1"
                v-model="start_date"
                :state="getValidationState(context)"
                :min="getStartMinDate()"
                :max="getStartMaxDate()"
                v-bind="context.ariaInput"
              />

              <b-form-invalid-feedback v-bind="context.ariaMsg">
                {{ context.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>

        <b-col>
          <validation-provider name="End Date" v-slot="context" rules="required">
            <b-form-group label="End Date">
              <b-form-datepicker
                v-model="end_date"
                :state="getValidationState(context)"
                :min="getEndMinDate()"
                locale="nb-NO"
                :start-weekday="1"
                v-bind="context.ariaInput"
              />

              <b-form-invalid-feedback v-bind="context.ariaMsg">
                {{ context.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>
        </b-col>
      </b-row>

      <b-row align-h="end">
        <b-col cols="auto">
          <b-button variant="primary" :disabled="invalid" type="submit">
            Add
          </b-button>
        </b-col>
      </b-row>
    </form>
  </validation-observer>
</template>

<script>
import { products } from '@/api'
import { getValidationState } from '../page-helpers'

export default {
  name: 'ProductVariationTemporaryPricing',
  props: { variation: { type: Object } },
  data() {
    return {
      price: this.variation.price.current.amount / 100,
      start_date: '',
      end_date: '',
    }
  },
  methods: {
    getValidationState,
    async submitFormData() {
      await products.variations.update(this.variation.id, {
        price: this.price,
        start_date: this.start_date,
        end_date: this.end_date,
      })

      this.$emit('close')
    },
    getStartMinDate() {
      return new Date()
    },
    getStartMaxDate() {
      return this.end_date ? new Date(this.end_date) : null
    },
    getEndMinDate() {
      const tomorrow = new Date(this.start_date ? this.start_date : this.getStartMinDate())

      tomorrow.setDate(tomorrow.getDate() + 1)

      return tomorrow
    },
  },
}
</script>
