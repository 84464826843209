<template>
  <validation-observer v-slot="{ invalid }">
    <form @submit.prevent="handleSubmit">
      <template>
        <b-row>
          <b-col>
            <validation-provider name="Start Time" v-slot="context" rules="required">
              <b-form-group label="Start Time">
                <b-form-datepicker
                  locale="nb-NO"
                  :start-weekday="1"
                  v-model="start_time"
                  :state="getValidationState(context)"
                  v-bind="context.ariaInput"
                />

                <b-form-invalid-feedback v-bind="context.ariaMsg">
                  {{ context.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>

          <b-col>
            <validation-provider name="End Time" v-slot="context" rules="required">
              <b-form-group label="End Time">
                <b-form-datepicker
                  locale="nb-NO"
                  :start-weekday="1"
                  v-model="end_time"
                  :state="getValidationState(context)"
                  v-bind="context.ariaInput"
                />

                <b-form-invalid-feedback v-bind="context.ariaMsg">
                  {{ context.errors[0] }}
                </b-form-invalid-feedback>
              </b-form-group>
            </validation-provider>
          </b-col>
        </b-row>

        <validation-provider name="Valid For" v-slot="context" rules="required">
          <b-form-group label="Valid For (Participants)">
            <b-form-input
              v-model="valid_for"
              placeholder="e.g 1"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Alert When" v-slot="context" rules="required">
          <b-form-group label="Alert When (Remaining Codes)">
            <b-form-input
              v-model="alert_when"
              placeholder="e.g 1"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Codes" v-slot="context" rules="required">
          <b-form-group label="Codes">
            <b-textarea
              v-model="codes"
              rows="10"
              placeholder="e.g A 1212121212"
              :state="getValidationState(context)"
              v-bind="context.ariaInput"
            />

            <b-form-invalid-feedback v-bind="context.ariaMsg">
              {{ context.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>

        <validation-provider name="Booking CSV Info" rules="required">
          <b-form-group label="CSV Booking Info">
            <froala v-model="variation.booking_csv_info" />
          </b-form-group>
        </validation-provider>

        <b-row align-h="end">
          <b-col cols="auto">
            <b-button type="submit" variant="primary" :disabled="$cannot('supplier.manage') || invalid">
              Add
            </b-button>
          </b-col>
        </b-row>
      </template>
    </form>
  </validation-observer>
</template>

<script>
import 'vue-multiselect/dist/vue-multiselect.min.css'
import { products } from '@/api'
import { getValidationState } from '../page-helpers'
import Froala from '@/components/Froala.vue'

export default {
  name: 'ProductVariationCSVCodesBatchForm',
  inject: ['$cannot'],
  components: {Froala },
  props: {
    variation: { type: Object },
  },

  data() {
    return {
      codes: '',
      alert_when: 1,
      start_time: '',
      end_time: '',
      valid_for: 1,
    }
  },
  methods: {
    getValidationState,

    async handleSubmit() {
      await products.variations.batches.create({
        codes: this.codes,
        alert_when: this.alert_when,
        start_time: this.start_time,
        variations: [{'id': this.variation.id}],
        product_id: this.variation.product_id,
        end_time: this.end_time,
        valid_for: this.valid_for,
        booking_csv_info: this.variation.booking_csv_info,
      })

      this.$bvToast.toast(`Codes for product variation uploaded.`, {
        title: 'Code batch uploaded',
        variant: 'primary',
      })

      this.$emit('close')
    },
  },
}
</script>
