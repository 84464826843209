<template>
  <v-md-editor
    :value="value"
    @change="onChange"
    height="400px"
    mode="edit"
    :placeholder="placeholder"
    left-toolbar="h bold italic | ul ol hr | link"
    right-toolbar="preview fullscreen"
  />
</template>

<script>
import '@kangc/v-md-editor/lib/style/base-editor.css'

import Vue from 'vue'
import VueMarkdownEditor from '@kangc/v-md-editor'
import githubTheme from '@kangc/v-md-editor/lib/theme/github.js'
import enUS from '@kangc/v-md-editor/lib/lang/en-US'

VueMarkdownEditor.lang.use('en-US', enUS)
VueMarkdownEditor.use(githubTheme)
Vue.use(VueMarkdownEditor)

export default {
  name: 'MarkdownEditor',
  props: {
    value: String,
    placeholder: { type: String, default: 'Enter content...' },
  },
  methods: {
    onChange(update) {
      this.$emit('input', update)
    },
  },
}
</script>
